<template>
  <div>
    <van-nav-bar title="第二个"
                 placeholder
                 fixed />
    {{msg}}
  </div>
</template>
<script>
export default {
  props: {},
  components: {},
  data () {
    return {
      msg: '第二个页签'
    }
  },
  created () { },
  mounted () { },
  methods: {}
}
</script>
<style lang="scss" scoped>
</style>